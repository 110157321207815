<template>
  <div class="product-details">
    <header
      class="products-header border-bottom"
      :class="{ isImageExpand }"
      @click="isImageExpand = !isImageExpand"
    >
      <img
        :src="
          productDetailsForWebsite.imagePath
            ? productDetailsForWebsite.imagePath.replace(
                '~',
                $store.getters['app/domainHost']
              )
            : ''
        "
      />
      <div class="product-title">
        <h1>{{ productDetailsForWebsite.name }}</h1>
        <small>{{t('website').products.clickImg}}</small>
      </div>
    </header>
    <b-container>
      <div class="product-content pt-2 px-lg-5">
        <div class="product-content-container">
          <b-breadcrumb class="px-0 pb-3">
            <b-breadcrumb-item active>{{t('website').products.allProducts}}</b-breadcrumb-item>
            <b-breadcrumb-item active>{{productDetailsForWebsite.mainCategory}}</b-breadcrumb-item>
            <b-breadcrumb-item active>{{productDetailsForWebsite.subCategory}}</b-breadcrumb-item>
          </b-breadcrumb>
          <h4 class="mb-2">{{t('website').products.productsDetails}}</h4>
          <div
            class="product-desc"
            v-html="productDetailsForWebsite.description"
          ></div>
          <span class="price" v-if="settings.showPrices"
            ><strong>{{ t("price") }} : </strong
            >{{
              productDetailsForWebsite.price
                ? productDetailsForWebsite.price
                : 0
            }}
            {{ t("syPond") }}</span
          >
        </div>
        <div class="products-cards-container pt-5">
          <h2 class="mb-3">{{ t("otherProducts") }}</h2>
          <b-row>
            <b-col
              cols="12"
              md="6"
              lg="4"
              v-for="(product, i) in productsListOther"
              :key="i"
              class="products-card-parent"
            >
              <div class="products-card">
                <router-link :to="'/products/' + product.id">
                  <h4 class="products-card-title">{{ product.name }}</h4>
                  <div class="products-card-image-desc-container">
                    <img
                      class="products-card-image"
                      :src="
                        product.imageDto.path
                          ? product.imageDto.path.replace(
                              '~',
                              $store.getters['app/domainHost']
                            )
                          : ''
                      "
                    />
                    <div
                      class="products-card-desc"
                      v-html="product.description"
                    ></div>
                    <span class="product-logo-title"
                      >ALFAISAL <span class="line-border"></span
                    ></span>
                  </div>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
  import { useUtils as useI18nUtils } from "@core/libs/i18n";
  import { mapGetters, mapActions, mapState } from "vuex";
  export default {
    setup() {
      const { t } = useI18nUtils();
      return { t };
    },
    props: {
      id: String,
    },
    data: () => ({
      isImageExpand: true,
    }),
    metaInfo() {
      return {
        titleTemplate: "Al Faisal - " + this.$t("title.products") + ' - ' + this.productDetailsForWebsite.name,
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: [
          {
            name: "description",
            content: this.productDetailsForWebsite.description,
          },
          {
            name: "keywords",
            content: "keywords, keywords, keywords, ...",
          },
        ],
      };
    },
    created() {
      this.getProductsByIdForWebsite(+this.id);
    },
    computed: {
      ...mapState({
        settings: (state) => state.setting.settings,
      }),
      ...mapGetters(["productDetailsForWebsite", "productsListOther"]),
    },
    methods: {
      ...mapActions(["getProductsByIdForWebsite"]),
    },
    watch: {
      id(id) {
        this.getProductsByIdForWebsite(+id);
      },
    },
    beforeDestroy() {
      this.$store.dispatch("app/setLoadingWebsite", true);
    },
  };
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
  .product-details {
    .products-header {
      cursor: pointer;
      transition: 0.4s;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.4s;
      }
      .product-title {
        transition: 0.4s;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        h1 {
          color: #fff;
        }
      }
      &.isImageExpand {
        height: 400px;
        .product-title {
          opacity: 1;
          background: rgba($color: #000000, $alpha: 0.6);
        }
      }
    }
    .product-content {
      .product-content-container {
        position: relative;
        p {
          line-height: 1.7;
        }
        .price {
          display: inline-block;
          border-bottom: solid 2px $secondaryPrimary;
          padding: 6px 8px;
          position: absolute;
          right: 0;
          color: $primary;
          font-size: 18px;
        }
      }
      .products-cards-container {
        position: relative;
        h2 {
          color: $primary;
        }
        .products-card-parent {
          margin-bottom: 2rem;
          .products-card {
            padding: 10px 0;
            width: 300px;
            margin: auto;
            position: relative;
            &:after {
              content: "";
              position: absolute;
              left: 0;
              height: 3px;
              background: $secondaryPrimary;
              top: 0;
              width: 100%;
            }
            .products-card-title {
              transition: all 0.4s linear;
              margin: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 2;
              color: #fff;
            }
            .products-card-image-desc-container {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 16px;
                width: 100%;
                background: rgba(0, 0, 0, 0.5);
                z-index: 1;
              }
              .products-card-image {
                height: 340px;
                width: 100%;
                z-index: 0;
              }
              .products-card-desc {
                pointer-events: none;
                transition: all 0.4s linear;
                position: absolute;
                left: -100%;
                bottom: 40px;
                z-index: -1;
                width: calc(100% + 14px);
                color: #fff;
                background: $secondaryPrimary;
                padding: 30px 1rem;
                padding-right: calc(1rem + 15px);
                text-align: center;
                line-height: 2;
                opacity: 0;
                visibility: hidden;
                &:after {
                  content: "";
                  position: absolute;
                  bottom: -15px;
                  width: 15px;
                  height: 15px;
                  right: 0;
                  background: #a97216;
                  clip-path: polygon(0 0, 100% 0, 100% 100%);
                }
              }
            }
            .product-logo-title {
              display: flex;
              align-items: center;
              letter-spacing: 12px;
              font-size: 12px;
              color: $primary;
              .line-border {
                margin-left: 12px;
                flex-grow: 1;
                height: 2px;
                background: $secondaryPrimary;
              }
            }
            &:hover {
              .products-card-title {
                top: 20%;
              }
              .products-card-image-desc-container {
                .products-card-desc {
                  left: 0;
                  z-index: 2;
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }
  [dir="ltr"] {
    .product-details {
      .product-content {
        .products-cards-container {
          .products-card-parent {
            .products-card {
              .products-card-image-desc-container {
                .products-card-desc {
                  &:after {
                    clip-path: polygon(0 0, 100% 0, 0 100%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
