var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-details"},[_c('header',{staticClass:"products-header border-bottom",class:{ isImageExpand: _vm.isImageExpand },on:{"click":function($event){_vm.isImageExpand = !_vm.isImageExpand}}},[_c('img',{attrs:{"src":_vm.productDetailsForWebsite.imagePath
          ? _vm.productDetailsForWebsite.imagePath.replace(
              '~',
              _vm.$store.getters['app/domainHost']
            )
          : ''}}),_c('div',{staticClass:"product-title"},[_c('h1',[_vm._v(_vm._s(_vm.productDetailsForWebsite.name))]),_c('small',[_vm._v(_vm._s(_vm.t('website').products.clickImg))])])]),_c('b-container',[_c('div',{staticClass:"product-content pt-2 px-lg-5"},[_c('div',{staticClass:"product-content-container"},[_c('b-breadcrumb',{staticClass:"px-0 pb-3"},[_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.t('website').products.allProducts))]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.productDetailsForWebsite.mainCategory))]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.productDetailsForWebsite.subCategory))])],1),_c('h4',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.t('website').products.productsDetails))]),_c('div',{staticClass:"product-desc",domProps:{"innerHTML":_vm._s(_vm.productDetailsForWebsite.description)}}),(_vm.settings.showPrices)?_c('span',{staticClass:"price"},[_c('strong',[_vm._v(_vm._s(_vm.t("price"))+" : ")]),_vm._v(_vm._s(_vm.productDetailsForWebsite.price ? _vm.productDetailsForWebsite.price : 0)+" "+_vm._s(_vm.t("syPond")))]):_vm._e()],1),_c('div',{staticClass:"products-cards-container pt-5"},[_c('h2',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.t("otherProducts")))]),_c('b-row',_vm._l((_vm.productsListOther),function(product,i){return _c('b-col',{key:i,staticClass:"products-card-parent",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('div',{staticClass:"products-card"},[_c('router-link',{attrs:{"to":'/products/' + product.id}},[_c('h4',{staticClass:"products-card-title"},[_vm._v(_vm._s(product.name))]),_c('div',{staticClass:"products-card-image-desc-container"},[_c('img',{staticClass:"products-card-image",attrs:{"src":product.imageDto.path
                        ? product.imageDto.path.replace(
                            '~',
                            _vm.$store.getters['app/domainHost']
                          )
                        : ''}}),_c('div',{staticClass:"products-card-desc",domProps:{"innerHTML":_vm._s(product.description)}}),_c('span',{staticClass:"product-logo-title"},[_vm._v("ALFAISAL "),_c('span',{staticClass:"line-border"})])])])],1)])}),1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }